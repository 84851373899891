.fullWidthWithChild {
  width: -webkit-fill-available;
}
.fullWidthWithChild > div {
  width: -webkit-fill-available;
}
.fullWidthAndHeightWithChild {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.fullWidthAndHeightWithChild > div {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.largerInput {
  width: 500px !important;
}
